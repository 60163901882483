<!--
 * @Author: zxf
 * @Date: 2022-10-14 11:17:50
 * @LastEditors: cgh
 * @LastEditTime: 2023-05-09 15:46:43
 * @Description: file content
-->
<!-- 火灾报警接入的视频展示 -->
<template>
  <div class="dev-c-video">
    <div
      v-if="spUrlList.length === 0"
      class="firewarn_movie"
    >
      <img
        style="width: 100%; height: 380px"
        src="/monitor/images/hj/hz_mr.png"
      >
      <img
        src="/monitor/images/hj/喇叭.svg"
        style="position: absolute;right:120px;top:390px"
      >
      <img
        src="/monitor/images/hj/扩大.svg"
        style="position: absolute;right:60px;top:390px"
      >
    </div>
    <div v-if="spUrlList.length >0">
      <videoHls
        style="height: 380px"
        :video-url="spUrlList[0]"
        vimage="/monitor/images/hj/hz_mr.png"
      />
    </div>
  </div>
</template>

<script>
import { videoService } from './video.service';
import config from '@/config';
import videoHls from '@/features/video/video-hls.vue';

export default {
  name: 'RunViedo',
  components: {
    videoHls
  },
  props: {
    bjxxId: {
      type: String,
      required: false,
      default: function () {
        return '';
      }
    }
  },
  data () {
    return {
      sbList: [],
      host: null,
      spUrlList: [],
      vimage: '/assets/video.png' // 视频封面地址
    };
  },
  methods: {
    getVideoHost (bjxxId) {
      if (!this.host) {
        const _this = this;
        videoService.getVideoHost().then(res => {
          _this.host = res.data;
          this.listSbxxByBjxxId(bjxxId);
        });
      } else {
        this.listSbxxByBjxxId(bjxxId);
      }
    },
    // 根据部件信息ID获取该部件对应的回路
    listSbxxByBjxxId (bjxxId) {
      if (bjxxId) {
        const _this = this;
        const hasToken = this.$HMCookies.get(config.localTokenName) || false;
        videoService.listSbByBjxxId(bjxxId).then(res => {
          _this.sbList = res.data;
          // 遍历设备list，拼接视频地址
          _this.spUrlList = [];
          _this.sbList.forEach(item => {
            const spurl = _this.host + '/' + item.ssdw + '/' +
              item.bh + '/hls.m3u8?time=' + new Date().getTime() + '&token=' + hasToken;
            _this.spUrlList.push(spurl);
          });
        });
      }
    }
  },
  watch: {
    bjxxId (val) {
      this.getVideoHost(val);
    }
  },
  mounted () {
    this.getVideoHost(this.bjxxId);
  }
};
</script>
<style lang="scss">

</style>
