import * as three from 'three';
import { cadService } from './cad.service';

// const axesHelper = new three.AxesHelper(1000000);
const menuCofig = [
  { name: '新增消防设备', key: 'add' },
  { name: '移动消防设备（再次点击鼠标完成设置）', key: 'move' },
  { name: '删除消防设备', key: 'del' }
];

const xfsbDm = [
  { bm: 'HZ001', mc: '感烟' },
  { bm: 'HZ002', mc: '感温' },
  { bm: 'HZ005', mc: '声光报警器' },
  { bm: 'HZ006', mc: '手动报警按钮' },
  { bm: 'HZ007', mc: '消防应急广播' },
  { bm: 'HZ015', mc: '警铃' },
  { bm: 'FH001', mc: '防火门' },
  { bm: 'FP004', mc: '排烟阀' },
  { bm: 'PS001', mc: '水流指示器' },
  { bm: 'PS005', mc: '末端试水装置' },
  { bm: 'QT004', mc: '压力开关' },
  { bm: 'FP002', mc: '送风阀' },
  { bm: 'PS002', mc: '信号蝶阀' },
  { bm: 'FH004', mc: '防火门监控器' },
  { bm: 'FP005', mc: '挡烟垂壁' },
  { bm: 'GS001', mc: '消防水池' },
  { bm: 'GS003', mc: '管网压力' },
  { bm: 'GS004', mc: '管网监控器' },
  { bm: 'HZ010', mc: '强切' },
  { bm: 'HZ012', mc: '火灾报警控制器' },
  { bm: 'PS004', mc: '报警阀组' },
  { bm: 'QT001', mc: '放气指示灯' },
  { bm: 'FP007', mc: '防火阀' },
  { bm: 'HZ016', mc: '电梯迫降' },
  { bm: 'XFQ002', mc: '消火栓启泵按钮' }

];

// 设备状态代码
const bjztDm = [
  { bm: '01', mc: '正常' },
  { bm: '10', mc: '报警', color: 0xe8684a, rgb: '#e8684a' },
  { bm: '20', mc: '故障', color: 0xf6bd16, rgb: '#f6bd16' },
  { bm: '99', mc: '屏蔽' }
];
let defaultXfsbIconMaterial = null;

let model = null;
let selectPoint = null;
let selectXfsb = null;

export class DxfControl {
  constructor (dxfViewer, showBjzt = false) {
    this.dxfViewer = dxfViewer;
    this.cadUuid = null;
    this.xfsb3DObjects = [];
    this.xfsbs = [];

    // 定时器
    if (showBjzt) {
      this.clock = setInterval(() => {
        const defaultColor = 0xffffff;
        this.xfsb3DObjects.forEach(mesh => {
          const xfsb = mesh.hmData;
          // 报警、故障状态，颜色一闪一闪
          const aZt = bjztDm.find(zt => zt.bm === xfsb.bjzt);
          if (aZt && aZt.color) { mesh.material.color.set(mesh.material.color.getHex() === defaultColor ? aZt.color : defaultColor); }
        });
        if (!this.dxfViewer.canvas.isConnected) {
          clearInterval(this.clock);
        } else {
          this.dxfViewer.Render();
        };
      }, 1000);
    }

    const _this = this;
    this.dialog = new Dialog();
    // 右键菜单工具类
    this.MenuHelper = new MenuHelper(e => {
      const menuKey = e.menu.key;
      if (e.menu.$parentMenu && e.menu.$parentMenu.key === 'add') {
        // 新增设备
        const xfsbEntity = {
          bjxxId: e.menu.key,
          cadId: _this.cadUuid,
          positionX: selectPoint.x,
          positionY: selectPoint.y
        };
        cadService.saveCadBjxx(xfsbEntity).then(data => {
          const hmData = _this.xfsbs.find(el => el.bjxxId === xfsbEntity.bjxxId);
          hmData.uuid = data.data.data;
          hmData.positionX = selectPoint.x;
          hmData.positionY = selectPoint.y;
          _this.createXfsb(selectPoint, hmData);
          document.getElementById('app').__vue__.$message('成功新增!');
        });
      } else if (menuKey === 'move' && selectXfsb) {
        // 移动设备
        model = 'move';
      } else if (menuKey === 'del' && selectXfsb) {
        // 删除消防设备
        cadService.deleteCadBjxx(selectXfsb.hmData.uuid).then(data => {
          _this.dxfViewer.GetScene().remove(selectXfsb);
          _this.dxfViewer.Render();
          _this.xfsb3DObjects = _this.xfsb3DObjects.filter(el => el.hmData.uuid !== selectXfsb.hmData.uuid);
          const hmData = _this.xfsbs.find(el => el.uuid === selectXfsb.hmData.uuid);
          hmData.uuid = hmData.positionX = hmData.positionY = null;
          document.getElementById('app').__vue__.$message('成功删除!');
        });
      }
    });

    // 获取鼠标选中的设备
    const getSelect3DObject = (domEvent) => {
      const _pointer = new three.Vector2();
      const rect = _this.dxfViewer.domContainer.getBoundingClientRect();
      _pointer.x = (domEvent.clientX - rect.left) / rect.width * 2 - 1;
      _pointer.y = -(domEvent.clientY - rect.top) / rect.height * 2 + 1;
      const _intersections = [];
      const _raycaster = new three.Raycaster();
      _raycaster.setFromCamera(_pointer, _this.dxfViewer.camera);
      _raycaster.intersectObjects(_this.xfsb3DObjects, true, _intersections);
      return _intersections[0] ? _intersections[0].object : null;
    };
    // 移动鼠标
    this.dxfViewer.domContainer.addEventListener('pointermove', e => {
      if (model === 'move') {
        // 拖动消防设备
        const canvasRect = e.target.getBoundingClientRect();
        const canvasCoord = { x: e.clientX - canvasRect.left, y: e.clientY - canvasRect.top };
        const point = _this.dxfViewer._CanvasToSceneCoord(canvasCoord.x, canvasCoord.y);
        selectXfsb.position.x = point.x;
        selectXfsb.position.y = point.y;
        _this.dxfViewer.Render();
      }

      // 改变鼠标样式
      const selectObj = getSelect3DObject(e);
      if (selectObj) {
        _this.dxfViewer.domContainer.style.cursor = 'pointer';
        model !== 'move' && _this.dialog.show(selectObj.hmData, e);
      } else {
        _this.dxfViewer.domContainer.style.cursor = 'auto';
        _this.dialog.dispose();
      }
    });

    // 按下鼠标按钮
    this.dxfViewer.Subscribe('pointerdown', e => {
      // 添加坐标轴
      // if (this.dxfViewer.GetScene().children.filter(item => item.type === 'AxesHelper').length === 0) {
      //   this.dxfViewer.GetScene().add(axesHelper);
      // }

      if (model === 'move') {
        // 再次点击鼠标，保存设备移动操作结果
        const selectXfsbBak = selectXfsb;
        cadService.updateCadBjxx(selectXfsb.hmData.uuid, {
          positionX: selectXfsb.position.x,
          positionY: selectXfsb.position.y
        }).then(data => {
          document.getElementById('app').__vue__.$message('成功移动!');
        }).catch(() => {
          model = 'move';
          selectXfsb = selectXfsbBak;
        });
      }

      model = null;
      selectPoint = null;
      selectXfsb = null;
    });

    // 释放鼠标按钮
    this.dxfViewer.Subscribe('pointerup', e => {
      // 查看模式
      if (this.readOnly === 'read') return;

      if (e.detail.domEvent.button === 2) {
        selectPoint = e.detail.position;
        selectXfsb = getSelect3DObject(e.detail.domEvent);

        // 打开菜单
        let menus = JSON.parse(JSON.stringify(menuCofig));
        if (selectXfsb) {
          menus = menus.filter(el => el.key === 'move' || el.key === 'del');
        } else {
          // 过滤待标记的设备图标
          const menuAdd = menus.find(el => el => el.key === 'add');
          menuAdd.subMenu = [];
          _this.xfsbs.forEach(aXfsb => {
            if (!aXfsb.uuid) {
              menuAdd.subMenu.push({
                name: `${aXfsb.bjqh}-${aXfsb.bjhlh}-${aXfsb.bjwh}  ${aXfsb.bjmc}  ${aXfsb.azwz}`,
                key: aXfsb.bjxxId
              });
            }
          });
          menus = menus.filter(el => el.key === 'add');
        }
        _this.MenuHelper.show(menus, e.detail.domEvent);
        _this.dialog.dispose();
      }
    });
  }

  // 初始化消防设备
  async loadXfsb (params, isFakeFire = false, filterXfsbDm) {
    // console.log('isFakeFire', isFakeFire);
    this.cadUuid = params.cadUuid; // 获取cad的ID
    this.dxfViewer.GetScene().remove(this.dxfViewer.GetScene().children);// 清空设备
    this.xfsbs = this.xfsb3DObjects = [];

    var _this = this;
    await cadService.queryCadBjxx(params).then(data => {
      _this.xfsbs = data.data.data;
      _this.xfsbs.forEach(aXfsb => {
        if (aXfsb.uuid) {
          if (!filterXfsbDm || filterXfsbDm.find(sbDm => sbDm === aXfsb.bjbm)) {
            _this.createXfsb({ x: aXfsb.positionX, y: aXfsb.positionY }, aXfsb);
          }
        }
      });

      // 模拟火灾，添加假设备
      if (isFakeFire) {
        const bounds = this.dxfViewer.bounds;
        const origin = this.dxfViewer.origin;
        const minX = bounds.minX - origin.x;
        const maxX = bounds.maxX - origin.x;
        const minY = bounds.minY - origin.y;
        const maxY = bounds.maxY - origin.y;
        const width = maxX - minX;
        const height = maxY - minY;
        const aXfsb = { bjxxId: 'fakeFire', positionX: minX + width / 2, positionY: minY + height / 2, bjqh: 999, bjmc: '烟感（模拟火灾）', bjzt: '10', bjztMc: '报警', scdwmc: '青鸟消防', azwz: '' };
        _this.createXfsb({ x: aXfsb.positionX, y: aXfsb.positionY }, aXfsb);
      }
    });
    return _this.xfsbs;
  }

  // 添加消防设备
  async createXfsb (position, xfsb) {
    // 加载消防设备图标

    if (xfsb && xfsb.bjmc && xfsb.bjmc.indexOf('模拟火灾') !== -1) {
      defaultXfsbIconMaterial = await new three.TextureLoader().load(`${process.env.BASE_URL}/3DModel/cad/image/HZ001.png`);
    } else if (!defaultXfsbIconMaterial) {
      defaultXfsbIconMaterial = await new three.TextureLoader().load(`${process.env.BASE_URL}/3DModel/cad/image/unkown.png`);
    }
    const xfsbIconMaterial = xfsbDm.find(el => el.bm === xfsb.bjbm) ? await new three.TextureLoader().load(`${process.env.BASE_URL}/3DModel/cad/image/${xfsb.bjbm}.png`) : defaultXfsbIconMaterial;
    // 添加消防设备mesh物体
    const width = 1000;
    // 缩小贴图
    xfsbIconMaterial.minFilter = three.NearestFilter;
    xfsbIconMaterial.offset = { x: -0.2, y: -0.2 };
    xfsbIconMaterial.repeat.set(1.4, 1.4);
    const material = new three.MeshBasicMaterial({ map: xfsbIconMaterial });
    const geometry = new three.CircleGeometry(width / 2, 32);
    const mesh = new three.Mesh(geometry, material);
    mesh.position.x = position.x;
    mesh.position.y = position.y;
    this.dxfViewer.GetScene().add(mesh);
    this.dxfViewer.Render();
    mesh.hmData = xfsb;
    this.xfsb3DObjects.push(mesh);
  }

  getXfsbs () {
    return this.xfsbs;
  }

  // 隐藏或显示全部消防设施
  showAllXfsb (flag) {
    this.xfsb3DObjects.forEach(el => { el.visible = flag; });
    this.dxfViewer.Render();
  }

  // 隐藏或显示消防设施
  showXfsb (xfsb, flag) {
    const a3DObject = this.xfsb3DObjects.find(el => el.hmData.uuid === xfsb.uuid);
    if (a3DObject) {
      a3DObject.visible = flag;
      this.dxfViewer.Render();
    }
  }

  // 移动查看消防设施
  lookXfsb (xfsb) {
    const a3DObject = this.xfsb3DObjects.find(el => el.hmData.uuid === xfsb.uuid);
    if (a3DObject) {
      const camera = this.dxfViewer.camera;
      this.dxfViewer.controls.dispose();
      camera.position.set(a3DObject.position.x, a3DObject.position.y, 1);
      // 放大查看
      // camera.zoom = 5;
      // camera.updateProjectionMatrix();
      this.dxfViewer.Render();
      this.dxfViewer._CreateControls();
      // 显示设备信息
      const vec2 = this.getScreenPosition(a3DObject, camera, this.dxfViewer.canvas);
      this.dialog.show(a3DObject.hmData, vec2);
      console.log('dxfViewer-2', this, camera.zoom);
    }
  }

  // 水波纹查看消防设施
  animationXfsb (bjxxId) {
    console.log('水波纹查看消防设施', bjxxId, this, this.xfsb3DObjects);
    const _this = this;
    const a3DObject = this.xfsb3DObjects.find(el => el.hmData.bjxxId === bjxxId);
    _this.animation && cancelAnimationFrame(_this.animation);
    _this.animation3DObject && _this.animation3DObject.scale.setScalar(1);
    _this.animation3DObject = a3DObject;
    if (!a3DObject) return;
    // 计算CAD的边界距离
    const bounds = _this.dxfViewer.bounds;
    const x = Math.abs(bounds.maxX - bounds.minX);
    const y = Math.abs(bounds.maxY - bounds.minY);
    const cadSize = y < x ? y : x;
    // 摄像机缩放比例
    let zoom = _this.dxfViewer.camera.zoom;
    let flag = true;
    let cnt = 1;
    const loop = function (timestamp) {
      if (!_this.dxfViewer.canvas.isConnected) {
        // 停止动画
        // console.log('停止动画');
        cancelAnimationFrame(_this.animation);
        _this.animation = null;
      } else {
        const fps = 60;// 一般按照60Hz算，每秒执行60次
        if (cnt % fps === 0) {
          flag = !flag;
          if (flag === true) { zoom = _this.dxfViewer.camera.zoom; }
        }
        const scale = a3DObject.scale;
        const offset = (cadSize / 5 / 1000) / (zoom < 1 ? 1 : zoom) / fps; // 每帧缩放多少倍(cad的5分之一，原图标是1000)
        flag && scale.addScalar(offset); // 放大
        !flag && scale.subScalar(offset); // 缩小
        _this.dxfViewer.Render();
        cnt++;
        _this.animation = requestAnimationFrame(loop);
        // console.log('水波纹动画', scale, cnt);
      }
    };
    _this.animation = requestAnimationFrame(loop);
  }

  // 世界坐标转换成屏幕坐标
  getScreenPosition (a3DObject, camera, canvas) {
    // 获取元素的视口内位置
    const rect = canvas.getBoundingClientRect();
    // 计算canvas的绝对位置(屏幕坐标)
    const absoluteTop = rect.top + window.scrollY;
    const absoluteLeft = rect.left + window.scrollX;

    // 世界坐标转换成标准设备坐标
    const vec3 = new three.Vector3(a3DObject.position.x, a3DObject.position.y, a3DObject.position.z);
    const vector = vec3.project(camera);

    // 标准设备坐标转屏幕坐标
    var w = canvas.width / 2;
    var h = canvas.height / 2;
    var x = Math.round(vector.x * w + w);
    var y = Math.round(-vector.y * h + h);
    return { clientX: absoluteLeft + x, clientY: absoluteTop + y };
  }

  // 是否启用查看/编辑模式
  setReadOnly (flag = true) {
    this.readOnly = flag ? 'read' : null;
  }
}

class MenuHelper {
  constructor (onMenuClick) {
    document.addEventListener('pointerdown', () => {
      if (this.oMenu) document.body.removeChild(this.oMenu);
      this.oMenu = null;
      return false;
    });
    this.onMenuClick = onMenuClick;
  }

  show (menu = menuCofig, event = { clientX: 0, clientY: 0 }) {
    const _this = this;
    const walkChild = function (parentMenu, parnetLi, subMenu, level) {
      if (!subMenu) return;
      const ul = document.createElement('ul');
      const ulStyle = `float: left;
      margin:0;padding:0;
      font-size: 16px;
      padding: 2px;
      border: 1px solid #979797;
      list-style-type: none;
      background: #f1f1f1;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, .6);
      max-height: 300px;
      overflow-y: auto;`;
      level === 0 ? ul.style = ulStyle : ul.style = ulStyle + 'display: none;position: absolute;';
      parnetLi.appendChild(ul);
      subMenu.forEach(el => {
        el.$parentMenu = parentMenu;
        const li = document.createElement('li');
        const font = document.createElement('font');
        font.innerHTML = el.name;
        font.style = 'display: inline-block;padding-left:20px;padding-right:10px';
        li.appendChild(font);
        li.style = `
        float: left;
        clear: both;
        height: 24px;
        cursor: pointer;
        line-height: 24px;
        white-space: nowrap;
        `;
        ul.appendChild(li);
        // 点击事件
        li.onpointerdown = function (e) {
          if (!el.subMenu && _this.onMenuClick) {
            _this.onMenuClick({
              menu: el,
              domEvent: e
            });
          }
        };
        walkChild(el, li, el.subMenu, level + 1);
      });
    };

    // 取li中最大的宽度, 并赋给同级所有li
    function setWidth (obj) {
      var maxWidth = 0;

      for (var i = 0; i < obj.children.length; i++) {
        var oLi = obj.children[i].children[0];

        var iWidth = oLi.clientWidth - parseInt(oLi.currentStyle ? oLi.currentStyle.paddingLeft : getComputedStyle(oLi, null).paddingLeft) - parseInt(oLi.currentStyle ? oLi.currentStyle.paddingRight : getComputedStyle(oLi, null).paddingRight);

        if (iWidth > maxWidth) maxWidth = iWidth;
      }

      for (i = 0; i < obj.children.length; i++) obj.children[i].children[0].style.width = maxWidth + 'px';
    }

    // 创建菜单元素
    const div = this.oMenu = document.createElement('div');
    div.id = 'rightMenu';
    div.style = 'position:absolute;font-size:16px;z-index: 10;margin:0;padding:0';
    div.style.top = event.clientY + 'px';
    div.style.left = event.clientX + 'px';
    walkChild(null, div, menu, 0);
    div.oncontextmenu = event => { return false; };
    document.body.appendChild(div);
    // 最大显示范围
    const maxWidth = document.documentElement.offsetWidth - div.offsetWidth;
    const maxHeight = document.documentElement.offsetHeight - div.offsetHeight;
    // 防止菜单溢出
    div.offsetTop > maxHeight && (div.style.top = maxHeight + 'px');
    div.offsetLeft > maxWidth && (div.style.left = maxWidth + 'px');

    const aUl = div.getElementsByTagName('ul');
    setWidth(aUl[0]);

    const bindEvent = function () {
      var oMenu = document.getElementById('rightMenu');
      // var aUl = oMenu.getElementsByTagName('ul');
      var aLi = oMenu.getElementsByTagName('li');

      var showTimer = null;
      var hideTimer = null;

      var maxWidth = 0;
      var maxHeight = 0;
      var aDoc = [document.documentElement.offsetWidth, document.documentElement.offsetHeight];
      for (var i = 0; i < aLi.length; i++) {
        // 为含有子菜单的li加上箭头
        var spanStyle = `
          display: inline-block;
          width: 6px;
          height: 6px;
          margin-right: 5px;
          box-sizing: content-box;
          background-color: transparent; /* 模块背景为透明 */
          transform: rotate(45deg); /*箭头方向可以自由切换角度*/`;
        if (aLi[i].getElementsByTagName('ul')[0]) spanStyle += 'border-color: rgb(149, 132, 136);border-style: solid;border-width: 4px 4px 0px 0px;';
        const span = document.createElement('span');
        span.style = spanStyle;
        aLi[i].appendChild(span);

        // 鼠标移入
        aLi[i].onmouseover = function () {
          var oThis = this;
          var oUl = oThis.getElementsByTagName('ul');
          // 鼠标移入样式
          oThis.style.backgroundColor = 'gray';

          // 显示子菜单
          if (oUl[0]) {
            clearTimeout(hideTimer);

            showTimer = setTimeout(function () {
              for (i = 0; i < oThis.parentNode.children.length; i++) {
                oThis.parentNode.children[i].getElementsByTagName('ul')[0] &&
                  (oThis.parentNode.children[i].getElementsByTagName('ul')[0].style.display = 'none');
              }
              oUl[0].style.display = 'block';
              oUl[0].style.top = oThis.offsetTop + 'px';
              oUl[0].style.left = oThis.offsetWidth + 'px';

              setWidth(oUl[0]);

              // 最大显示范围
              maxWidth = aDoc[0] - oUl[0].offsetWidth;
              maxHeight = aDoc[1] - oUl[0].offsetHeight;

              // 防止溢出
              maxWidth < getOffset.left(oUl[0]) && (oUl[0].style.left = -oUl[0].clientWidth + 'px');
              maxHeight < getOffset.top(oUl[0]) && (oUl[0].style.top = -oUl[0].clientHeight + oThis.offsetTop + oThis.clientHeight + 'px');
            }, 300);
          }
        };

        // 鼠标移出
        aLi[i].onmouseout = function () {
          var oThis = this;
          // var oUl = oThis.getElementsByTagName('ul');
          // 鼠标移出样式
          oThis.style.backgroundColor = '';
          clearTimeout(showTimer);
          hideTimer = setTimeout(function () {
            for (i = 0; i < oThis.parentNode.children.length; i++) {
              oThis.parentNode.children[i].getElementsByTagName('ul')[0] &&
                (oThis.parentNode.children[i].getElementsByTagName('ul')[0].style.display = 'none');
            }
          }, 300);
        };
      }

      var getOffset = {

        top: function (obj) {
          return obj.offsetTop + (obj.offsetParent ? getOffset.top(obj.offsetParent) : 0);
        },

        left: function (obj) {
          return obj.offsetLeft + (obj.offsetParent ? getOffset.left(obj.offsetParent) : 0);
        }

      };
    };
    // 绑定事件
    bindEvent();
  }
}

class Dialog {
  // 显示
  show (xfsbData, event = { clientX: 0, clientY: 0 }) {
    if (xfsbData !== this.xfsbData) { this.dispose(); this.xfsbData = xfsbData; };
    if (this.dialog) return; // 已创建
    const createRow = function (parentDom, title, text, textColor) {
      const row = document.createElement('div');
      row.style = 'display: flex;background-color: #ffffff;z-index: 999;';
      const titleDiv = document.createElement('div');
      titleDiv.style.minWidth = '75px';
      titleDiv.innerText = title;
      row.appendChild(titleDiv);
      const textDiv = document.createElement('div');
      textDiv.style.minWidth = '75px';
      if (textColor) textDiv.style.color = textColor;
      textDiv.innerText = text;
      row.appendChild(textDiv);
      parentDom.appendChild(row);
    };
    // 创建元素
    const div = this.dialog = document.createElement('div');
    div.id = 'dxf_dialog';
    div.style = `position: absolute;margin: 0px;padding: 5px;
                 font-size: 14px;line-height: 18px;color: black;
                 background-color: #fffefe;border: 3px solid #1385e5;
                 border-radius: 10px;z-index: 9999;`;
    div.style.top = event.clientY + 'px';
    div.style.left = event.clientX + 'px';
    document.body.appendChild(div);
    const titles = ['部件号：', '部件名称：', '部件状态：', '生产单位：', '安装位置：'];
    const text = [
      `${xfsbData.bjqh ? xfsbData.bjqh : ''}-${xfsbData.bjhlh ? xfsbData.bjhlh : ''}-${xfsbData.bjwh ? xfsbData.bjwh : ''}`,
       `${xfsbData.bjmc}`, `${xfsbData.bjztMc}`, `${xfsbData.scdwmc}`, `${xfsbData.azwz}`];
    let textColor = '#10b6ff';
    const aZt = bjztDm.find(zt => zt.bm === xfsbData.bjzt);
    if (aZt && aZt.rgb) textColor = aZt.rgb;

    titles.forEach((title, i) => {
      createRow(div, title, text[i], i === 2 ? textColor : null);
    });

    // 最大显示范围
    const maxWidth = document.documentElement.offsetWidth - div.offsetWidth;
    const maxHeight = document.documentElement.offsetHeight - div.offsetHeight;
    // 防止菜单溢出
    div.offsetTop > maxHeight && (div.style.top = maxHeight + 'px');
    div.offsetLeft > maxWidth && (div.style.left = maxWidth + 'px');
  }

  // 销毁
  dispose () {
    if (this.dialog) {
      this.dialog.parentElement.removeChild(this.dialog);
      this.dialog = null;
      this.xfsbData = null;
    }
  }
}
