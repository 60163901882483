<!--
 * @Author: DQL
 * @Date: 2022-11-30 10:13:40
 * @LastEditors: PXH
 * @LastEditTime: 2023-12-25 20:03:06
 * @Description: file content
-->
<template>
  <div class="dev-o-echart">
    <div
      v-show="dataList.length > 0"
      class="div-gd-kg"
      @click="handlGbgd()"
    >
      {{ gdIshowWz }}
    </div>
    <div
      id="div-jrfxsssj"
      class="div-body"
    >
      <div class="div-body-content">
        <div
          v-for="(item,index) in dataList"
          :key="index"

          class="div-body_item"
        >
          <div class="div-item_img" />
          <div :class="bgStr+item.fxdjbm">
            {{ item.sjlx }}
          </div>
          <div style="margin: 20px;">
            {{ item.dwmc }}
          </div>

          <div style="margin: 20px;">
            {{ item.bjmc }}
          </div>

          <div class="div-azwz">
            <!-- <image
            class="dev-jz-img"
            src="@/static/iffIcons/tj/建筑.png"
          /> -->
            <div>{{ item.azwz }}</div>
          </div>

          <div style="margin: 20px;">
            {{ item.sjsj }}
          </div>

          <div
            v-if="item.sfsd"
            class="div-right"
          >
            <div
              :style="item.sfsd?'color:#11f11e':''"
            >
              送达
            </div>
            <div
              :style="item.sfhc?'color:#11f11e;margin: 10px;':'margin: 10px;'"
            >
              核查
            </div>
          </div>
          <div
            v-if="!item.sfsd"
            class="div-right"
            style="margin-right: 20px"
          >
            <div
              :style="!item.sfsd?'color:#11f11e':''"
            >
              {{ item.cljg }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { monitorService } from '../monitor.service';

export default {
  name: 'Jrfxsssj',
  props: {
    // chartData: {
    //   required: false,
    //   type: Array,
    //   default: function () {
    //     return [];
    //   }
    // }
  },
  data () {
    return {
      loading: false,
      gdIshow: true,
      gdIshowWz: '关闭滚动',
      bgStr: 'div-fxdj-bg_',
      dataList: [
      ]
    };
  },
  methods: {

    /**
     * 获取当前电气火灾设备情况
     */
    queryJrfxSJ () {
      const _this = this;
      monitorService.queryYxbgSssj()
        .then(res => {
          _this.dataList = res.data;
          console.log('实时监控数据', res.data);
          // 遍历数组如果sjlx包含1则fxdjbm为one

          _this.dataList.forEach(item => {
            if (item.sjlx.includes('一级') || item.sjlx.includes('火')) {
              item.fxdjbm = 'one';
            } else if (item.sjlx.includes('二级')) {
              item.fxdjbm = 'two';
            } else if (item.sjlx.includes('三级')) {
              item.fxdjbm = 'three';
            };

            // // 如果cljg为已送达则sfsd为true
            if (item.cljg && item.cljg.includes('送达')) {
              item.sfsd = true;
              item.sfhc = false;
            } else {
              item.sfsd = false;
            };

            // sjsj 设置为 yyyy-MM-dd hh24:mi:ss格式
            item.sjsj = item.sjsj.substring(0, 19);
          });
        })
        .catch(error => {
          console.log('获取当前电气火灾设备信息失败！', error);
        })
        .finally(() => {

        });
    },
    handlGbgd () {
      this.gdIshow = !this.gdIshow;
      this.gdIshowWz = this.gdIshow ? '关闭滚动' : '开启滚动';
    }

  },
  watch: {
    // dataList (newVal, oldVal) {
    //   if (newVal) {
    //     this.getServerData();
    //     this.getGwylTimeOut();
    //   }

  },
  beforeDestroy () {
    // this.autoScroll(true);
  },

  mounted () {
    const _this = this;
    // _this.autoScroll();

    _this.queryJrfxSJ();

    // // 拿到表格挂载后的真实DOM
    const divData = document.getElementById('div-jrfxsssj');
    setInterval(() => {
      if (!_this.gdIshow) return;
      // 元素自增距离顶部1像素
      divData.scrollTop += 1;
      // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
      if (divData.clientHeight + divData.scrollTop === divData.scrollHeight) {
        // 重置table距离顶部距离
        divData.scrollTop = 0;
      }
    }, 50);

    setInterval(() => {
      _this.queryJrfxSJ();
    }, 5000);

    // const
  }
};
</script>

<style lang="less">
.div-gd-kg{
  width: 56px;
  padding: 4px;
  border-radius: 2px;
  background-color: #3263d1;
  color: #fff;
  position: absolute;
  right: 10px;
  top: -30px;
}
.div-body{
  // font: 20px;

  height: 320px;
  overflow-y: auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  &_item{
    display: flex;
    width: 100%;
    height: 40px;
    background-color: #152a58;
    align-items: center;
    position: relative;
    margin: 5px;
    border-radius: 3px;
    // animation: scroll 5s linear infinite;
  }

}
/* 鼠标悬停停止滚动 */
// .div-body_item:hover{
//   animation-play-state: paused;
// }
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.div-body::-webkit-scrollbar:vertical {
  width: 6px;
}

.div-body::-webkit-scrollbar-thumb {
  background: #09369A;
}
.div-body::-webkit-scrollbar-track {

  background: #08193F;
}
.div-body-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.div-item_img{
  // width: 20px;
  // height: 20px;
  // background-color: rgb(57, 59, 59);
  // background-size: 20px 20px;
  // background: url('@/static/iffIcons/dqhz/tz.svg') no-repeat center right;
  margin: 0 10px;

}
.div-item_num{

  display: flex;
  width: 60%;
  margin-left: 200px;
  &_test{
    width: 25%;
    margin: auto;
    font-size: 22px;
    color: #fff;
  }
}
.div-fxdj-bg{
  // background-color: #20283b;
  font: 20px;
  &_tz{
    background-color: #20283b;
    color: red;

  }
  &_one{
    // background-color: #20283b;
    color: #f0751e;
  }
  &_two{
    // background-color: #24383a;
    color: #fbe714;
  }
  &_three{
    // background-color: #23413b;
    color: #2f5ef8;
  }
  &_total{
    background-color: #0a314e;
    color: #43535f;
  }
}
.div-right{
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
}
.dev-jz-img{
  width: 14px;
  height: 12.9px;
  margin: 5px;
}
.div-azwz{
  display: flex;
  align-items: center;

  margin: 20px;
}

</style>
