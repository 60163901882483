<template>
  <div class="npl-zb-model">
    <audio
      id="bjs"
      ref="introduceMusic"
      muted="muted"
      style=" width: 200px;height: 10px;"
      @ended="introduceMusicEndFunc"
    />
    <div
      class="overlay"
      :class="{ 'is-visible': visible }"
    />
    <div v-if="introduceStatus">
      <div class="dev-women">
        <img
          :show="introduceStatus&&introduceShow"
          style="position: fixed;height: 90%;"
          :style="[womenPositionStyle]"
          src="/assets/women.png"
        >
      </div>
      <div class="dev-bg">
        <div
          class="introduce_msg"
        >
          <div class="dev-text">
            {{ introduceMsg }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config.js';
import { nplService } from '@/features/virtual/npl/npl.service';
import { cancelInstruct, instruct, unlockInstruct } from '@/features/feature.common.service';
import EventBus from './../../event-bus';
const { wsApi } = config.ctx;
let _this = null;

export default {
  name: 'Introduce',
  components: {

  },
  // props: {
  //   introduceStatus: {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  data () {
    return {
      womenPosition: 'center',
      introduceShow: true,
      introduce_move: false,
      visible: true,
      websocket: null,
      seesionId: null,
      instructNmu: 0,
      introduceStatus: false, // 介绍状态
      introduceAll: false, // 所有平台介绍状态
      introduceMsg: null,
      activeIndex: 'index',
      zl: null,
      closeNpl: false,
      music: '',
      introductionZt: [
        // {
        //   type: 'all',
        //   sm: '现在请大家跟随我回到中央位置，接下来为大家介绍消防监控大屏系统。',
        //   robotPosition: 'center',
        //   style: []
        // },
        // {
        //   type: 'all',
        //   sm: '大屏以全局的视角向使用者展现所有联网消防设施的运行状态。',
        //   robotPosition: 'center',
        //   style: []
        // },
        {
          type: 'left',
          sm: '首先左侧包括有机器人监控室',
          robotPosition: 'center',
          style: [],
          music: '/assets/introduc/index_left.wav'
        },
        {
          type: 'right',
          sm: '右侧是数据统计情况，包括今日火警、月度报警量、今日设备状态和近6个月的报警情况',
          robotPosition: 'center',
          style: [],
          music: '/assets/introduc/index_right.wav'
        }
        // {
        //   type: 'lwzt',
        //   sm: '当前联网单位状态，记录目前所有联网单位的联网状态',
        //   robotPosition: 'center',
        //   style: [],
        //   music: '/assets/introduc/lwzt.wav'
        // }
      ],
      introductionZt_Left: [

        {
          type: 'jqrjks',
          sm: '机器人监控室，统计机器人运行时长',
          robotPosition: 'center',
          style: [],
          music: '/assets/introduc/jqrjks.wav'
        },
        {
          type: 'jrsbzt',
          sm: '今日设备情况统计，包括正常数、报警数、故障数和屏蔽数',
          robotPosition: 'center',
          style: [],
          music: '/assets/introduc/jrsbzt.wav'
        },
        {
          type: 'xfsbsl',
          sm: '消防设备数量，统计所有单位的设备情况',
          robotPosition: 'center',
          style: [],
          music: '/assets/introduc/xfsbsl.wav'
        }
      ],
      introductionZt_Right: [

        {
          type: 'jrhj',
          sm: '今日火警，记录今天发生的火警情况',
          robotPosition: 'center',
          style: [],
          music: '/assets/introduc/jrhj.wav'
        },
        {
          type: 'ydbjl',
          sm: '月度信息、报警量',
          robotPosition: 'center',
          style: [],
          music: '/assets/introduc/ydbjl.wav'
        },
        {
          type: 'ydjkbg',
          sm: '月度监控报告',
          robotPosition: 'center',
          style: [],
          music: '/assets/introduc/ydjkbg.wav'
        },
        {
          type: 'bjtj',
          sm: '近6个月报警统计，统计近6月份的报警数据',
          robotPosition: 'center',
          style: [],
          music: '/assets/introduc/bjtj.wav'
        }
      ],

      // introductionDetail: [
      //   {
      //     type: 'left',
      //     sm: '首先左侧包括有机器人监控室、消防设备和消防维保情况',
      //     robotPosition: 'center',
      //     style: []
      //   },
      //   {
      //     type: 'right',
      //     sm: '右侧包括今日值班信息、火警信息和官网压力和水位监控',
      //     robotPosition: 'center',
      //     style: []
      //   },
      //   {
      //     type: 'top',
      //     sm: '头部是今日火警数、误报火警数、真实火警数和联动测试数',
      //     robotPosition: 'left',
      //     style: []
      //   },
      //   {
      //     type: 'bottom',
      //     sm: '底部是建筑物详情，包括建筑物详情信息和实时监控视频',
      //     robotPosition: 'left',
      //     style: []
      //   }

      // ],
      // introductionDetail_Left: [
      //   {
      //     type: 'jqrjks',
      //     sm: '机器人监控室，统计机器人运行时长',
      //     robotPosition: 'center',
      //     style: []
      //   },
      //   {
      //     type: 'xfsb',
      //     sm: '消防设备',
      //     robotPosition: 'center',
      //     style: []
      //   },
      //   {
      //     type: 'xfwbqk',
      //     sm: '消防维保情况',
      //     robotPosition: 'center',
      //     style: []
      //   }
      // ],
      // introductionDetail_Right: [
      //   {
      //     type: 'jrzb',
      //     sm: '今日值班，显示当天值班人员信息',
      //     robotPosition: 'center',
      //     style: []
      //   },
      //   {
      //     type: 'hjxx',
      //     sm: '火警信息，统计火警数据',
      //     robotPosition: 'center',
      //     style: []
      //   },
      //   {
      //     type: 'gwyl',
      //     sm: '官网压力，展示管网的压力',
      //     robotPosition: 'center',
      //     style: []
      //   },
      //   {
      //     type: 'swjk',
      //     sm: '水位监控，展示水池的水位高度',
      //     robotPosition: 'center',
      //     style: []
      //   }
      // ],
      introductionDetail_Sys: [
        {
          type: 'all',
          sm: '现在跳转这里是联网单位中物资大厦的监控中心',
          robotPosition: 'center',
          style: [],
          music: '/assets/introduc/all.wav'
        },
        // {
        //   type: 'left',
        //   sm: '在这里可以查看消防设备数量、消防维保情况',
        //   robotPosition: 'center',
        //   style: [],
        //   music: '/assets/introduc/left.wav'
        // },
        {
          type: 'top',
          sm: '火警情况',
          robotPosition: 'left',
          style: [],
          music: '/assets/introduc/top.wav'
        },
        {
          type: 'bottom',
          sm: '建筑物详情，及对应的实时视频',
          robotPosition: 'left',
          style: [],
          music: '/assets/introduc/bottom.wav'
        },
        {
          type: 'right',
          sm: '今日值班情况、火警信息、管网压力和水位监控',
          robotPosition: 'left',
          style: [],
          music: '/assets/introduc/right.wav'
        }
      ],
      introduceMusicEnd: null,
      socketOpen: false
      // introductionEnd: [
      //   {
      //     type: 'all',
      //     sm: '目前介绍已全部完毕，谢谢大家',
      //     robotPosition: 'center',
      //     style: []
      //   }
      // ]

    };
  },
  computed: {
    womenPositionStyle () {
      var womenPositionStyle = {
        top: '70px',
        left: '0px'
      };
      if (this.womenPosition && this.womenPosition === 'left') {
        womenPositionStyle.top = '70px';
        womenPositionStyle.left = '0px';
      } else if (this.womenPosition && this.womenPosition === 'center') {
        womenPositionStyle.top = '70px';
        womenPositionStyle.left = '800px';
      } else if (this.womenPosition && this.womenPosition === 'right') {
        womenPositionStyle.top = '70px';
        womenPositionStyle.left = '1600px';
      }
      return womenPositionStyle;
    }
  },
  methods: {
    togglePlayPause () {
      console.log('togglePlayPause');
      const music = this.$refs.introduceMusic;
      console.log(music.paused);
      if (this.introduceStatus && music.paused) {
        music.play();
      } else if (this.introduceStatus && !music.paused) {
        music.pause();
      }
    },
    introduceMusicEndFunc () {
      this.introduceMusicEnd();
    },
    startIntrouduce_all () {
      if (this.introduceStatus) {
        return;
      }
      this.startIntrouduce_zt();
    },
    // endIntrouduce () {
    //   const _this = this;
    //   _this.introduceMsg = '';
    //   var list = JSON.parse(JSON.stringify(_this.introductionEnd));
    //   // 开始介绍
    //   this.startNodeIntroduce(list.reverse(), '', '', null);
    // },
    startIntrouduce_zt () {
      this.introduceStatus = true;
      const _this = this;
      var list = JSON.parse(JSON.stringify(_this.introductionZt));
      // 开始介绍
      this.startNodeIntroduceNew(list.reverse(), 'introduce_zt_start', 'introduce_zt_stop', this.startIntrouduce_sys);
    },
    startIntrouduce_sys () {
      EventBus.$emit('introduction_goto_sys');
      this.introduceStatus = true;
      const _this = this;
      var list = JSON.parse(JSON.stringify(_this.introductionDetail_Sys));
      // 开始介绍
      this.startNodeIntroduceNew(list.reverse(), 'introduce_zt_start', 'introduce_zt_stop', null);
    },

    startIntroductionZt_Left () {
      EventBus.$emit('introduction_goto_sys');
      this.introduceStatus = true;
      const _this = this;
      var list = JSON.parse(JSON.stringify(_this.introductionZt_Left));
      // 开始介绍
      this.startNodeIntroduceNew(list.reverse(), 'introductionZt_Left_start', 'introductionZt_Left_stop', this.startIntroductionZt_Right);
    },
    startIntroductionZt_Right () {
      this.introduceStatus = true;
      const _this = this;
      var list = JSON.parse(JSON.stringify(_this.introductionZt_Right));
      // 开始介绍
      this.startNodeIntroduceNew(list.reverse(), 'introductionZt_Right_start', 'introductionZt_Right_stop');
    },

    startNodeIntroduceNew (list, eventName, endEventName, nextFunc) {
      var active = list.pop();
      const _this = this;
      if (active != null && list.length > 0) {
        setTimeout(() => {
          // 激活节点
          this.changeIntroduceMsg(active.sm); // 播报字幕
          EventBus.$emit(eventName, active.type);
          this.changeWomenPosition(active.robotPosition);
          // 语音描述
          _this.introduceVoice = this.$refs.introduceMusic;
          _this.introduceVoice.src = active.music;
          var func = () => {
            // 如果introduceCancel为true，说明是取消了介绍，不需要执行下一步，也不需要发送指令
            if (_this.introduceCancel) {
              if (endEventName) {
                EventBus.$emit(endEventName);
              }
              _this.introduceStatus = false;
              _this.introduceMsg = '';
              _this.introduceCancel = false;
              return;
            }
            this.startNodeIntroduceNew(list, eventName, endEventName, nextFunc);
          };
          _this.introduceMusicEnd = func;
          setTimeout(() => {
            _this.introduceVoice.play();
          }, 500);
        }, 500);
      } else if (active != null && list.length === 0) {
        setTimeout(() => {
          // 激活节点
          this.changeIntroduceMsg(active.sm); // 播报字幕
          EventBus.$emit(eventName, active.type);
          this.changeWomenPosition(active.robotPosition);
          // 语音描述

          // console.log(this.$refs.introduceMusic);
          _this.introduceVoice = this.$refs.introduceMusic;
          _this.introduceVoice.src = active.music;
          var func = () => {
            if (endEventName) {
              EventBus.$emit(endEventName);
            }
            this.introduceStatus = false;
            this.introduceMsg = '';
            // 如果introduceCancel为true，说明是取消了介绍，不需要执行下一步，也不需要发送指令
            if (_this.introduceCancel) {
              _this.introduceCancel = false;
              return;
            }
            if (nextFunc) {
              nextFunc instanceof Function && nextFunc();
            } else {
              EventBus.$emit('introduce-goto-sy');
              if (this.zl === 'start') {
                return;
              }
              // 如果为commonIntrouduce4 则延迟10秒
              if (this.zl === 'commonIntrouduce4') {
                setTimeout(() => {
                  this.fszl('', this.zl);
                }, 10000);
              } else {
                this.fszl('', this.zl);
              }
              this.introduceMsg = '';
            }
          };

          _this.introduceMusicEnd = func;
          // console.log(active);
          setTimeout(() => {
            _this.introduceVoice.play();
          }, 500);
        }, 500);
      }
    },

    startNodeIntroduce (list, eventName, endEventName, nextFunc) {
      // console.log(list);
      var active = list.pop();
      // const container = document.querySelector('.introduce_msg');
      // container.className = 'introduce_msg';
      if (active != null && list.length > 0) {
        setTimeout(() => {
          // 激活节点
          setTimeout(() => {
            this.changeIntroduceMsg(active.sm); // 播报字幕
          }, 1000);
          EventBus.$emit(eventName, active.type);
          this.changeWomenPosition(active.robotPosition);
          // 语音描述
          nplService.play(active.sm, () => {
            // 如果introduceCancel为true，说明是取消了介绍，不需要执行下一步，也不需要发送指令
            if (_this.introduceCancel) {
              if (endEventName) {
                EventBus.$emit(endEventName);
              }
              _this.introduceStatus = false;
              _this.introduceMsg = '';
              _this.introduceCancel = false;
              return;
            }
            this.startNodeIntroduce(list, eventName, endEventName, nextFunc);
          }, 70);
        }, 500);
      } else if (active != null && list.length === 0) {
        setTimeout(() => {
          // 激活节点
          setTimeout(() => {
            this.changeIntroduceMsg(active.sm); // 播报字幕
            // container.classList.add('move'); // 添加字幕播报效果
          }, 1000);

          EventBus.$emit(eventName, active.type);
          this.changeWomenPosition(active.robotPosition);

          nplService.play(active.sm, () => {
            if (endEventName) {
              EventBus.$emit(endEventName);
            }
            this.introduceStatus = false;
            this.introduceMsg = '';
            // 如果introduceCancel为true，说明是取消了介绍，不需要执行下一步，也不需要发送指令
            if (_this.introduceCancel) {
              _this.introduceCancel = false;
              return;
            }
            if (nextFunc) {
              nextFunc instanceof Function && nextFunc();
            } else {
              EventBus.$emit('introduce-goto-sy');
              if (this.zl === 'start') {
                return;
              }
              this.fszl('', this.zl);
              this.introduceMsg = '';
            }
          });
        }, 500);
      } else {

      }
    },
    run (x, y) {
      this.$refs.ani.step({
        translateX: x
        // rotateY: 180
      }, {
        timingFunction: 'ease-in',
        duration: 500
      });
      // 开始执行动画
      this.$refs.ani.run(() => {
        console.log('动画支持完毕');
      });
    },
    changeWomenPosition (position) {
      if (position !== this.womenPosition) {
        this.introduceShow = false;
        this.womenPosition = position;
        var _this = this;
        setTimeout(() => {
          _this.introduceShow = true;
        }, 100);
      }
    },
    changeIntroduceMsg (msg) {
      this.introduceMsg = msg;
    },
    beforeEnter (el) {
      console.log('beforeEnter');
    },
    enter (el, done) {
      console.log('enter');
    },
    websocketInit () {
      const hasToken = this.$HMCookies.get(config.localTokenName) || false;
      if (hasToken) {
        const wsprocotol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        let apiContext = '';
        if (!wsApi) {
          apiContext = '/api/iff';
        }

        console.log(`${wsprocotol}${wsApi || window.location.host}${apiContext}/iff/socket/ssjk/`);
        // this.websocket = new WebSocket(`${wsprocotol}${wsApi || window.location.host}${apiContext}/iff/socket/ssjk/${this.seesionId}?token=${hasToken}`);
        this.websocket = new WebSocket(`ws://localhost:18070/iff/iff/socket/ssjk/${this.seesionId}?token=${hasToken}`);
        this.websocket.onopen = () => {
          _this.socketOpen = true;
          console.log('Socket连接成功');
          this.websocket.send(JSON.stringify({ layout: 'Dplayout' }));
        };
        this.websocket.onmessage = (res) => {
          const resdata = JSON.parse(res.data);
          if (resdata.key === 'introduceDp') {
            console.log(resdata.values);
            if (resdata.values) {
              EventBus.$emit('reviceIntroduce', resdata.values);
            }
          }
        };
        this.websocket.onerror = () => {
          _this.socketOpen = false;
          console.log('Socket连接发生错误，将在5秒后重试...');
          setTimeout(() => {
            if (!this.socketOpen) {
              _this.socketOpen = true;
              this.websocketInit();
            }
          }, 5000);
        };
        this.websocket.onclose = () => {
          _this.socketOpen = false;
          console.log('Socket连接已关闭，将在5秒后重试...');
          setTimeout(() => {
            if (!this.socketOpen) {
              _this.socketOpen = true;
              this.websocketInit();
            }
          }, 5000);
        };
      }
    },
    transformUint8ArrayToBase64 (array) {
      let binary = '';
      for (var len = array.byteLength, i = 0; i < len; i++) {
        binary += String.fromCharCode(array[i]);
      }
      return window.btoa(binary).replace(/=/g, '');
    },
    randomSessionId () {
      const ua = new Uint8Array(20);
      new DataView(ua.buffer).setUint32(0, Math.floor(+new Date() / 1000));
      const crypto = window.crypto || window.msCrypto;
      if (crypto) {
        crypto.getRandomValues(ua.subarray(4, 20));
      }
      return (
        this.transformUint8ArrayToBase64(ua)
          .replace(/\+/g, '-')
          .replace(/\//g, '-')
      );
    },
    start () {
      this.introduceCancel = false;
      // 解锁指令
      unlockInstruct().then(rsp => {
        console.log('解锁指令成功！');
        instruct({ fszl: 'start', fqdx: 'dp', fsdx: 'dp' }).then(rsp => {
          console.log('指令start发送成功！');
        }).catch(err => {
          console.error('发送start指令失败', err);
        });
      }).catch(err => {
        console.error('解锁指令失败', err);
      });
    },
    fszl (_fsdx, _zl) {
      instruct({ fszl: _zl, fqdx: 'dp', fsdx: _fsdx }).then(rsp => {
        console.log('指令发送成功！' + _zl);
      }).catch(err => {
        console.error('发送指令失败', err);
      });
    },
    setCloseNpl (data) {
      EventBus.$emit('setCloseNpl', data);

      this.closeNpl = data;
    },
    openYysb () {
      EventBus.$emit('openYysb');
    },
    stopPlay () {
      EventBus.$emit('stopPlay');
    }
  },

  mounted () {
    const _this = this;
    // 切换不同版本
    if (window.location.href.indexOf('localhost') > -1) {
      this.seesionId = 'dpLayout' + this.randomSessionId();
    } else {
      this.seesionId = this.randomSessionId();
    }
    this.websocketInit();
    // 增加快捷键设置
    document.addEventListener('keypress', (event) => {
      if (event.shiftKey && event.code === 'Digit5') {
        // 数字键5
        this.start();
      }
      if (event.shiftKey && event.code === 'Digit8') {
        _this.togglePlayPause();
      }
      if (event.shiftKey && event.code === 'Digit9') {
        // 数字键5
        _this.stopPlay();
        _this.introduceAll = false;
        _this.setCloseNpl(false);
        _this.introduceCancel = true;
        _this.introduceStatus = false;
        cancelInstruct();
      }
    });

    EventBus.$on('commonIntrouduce', function (data) {
      _this.introduceStatus = true;
      console.log(data);
      var list = JSON.parse(data.introduction);
      _this.startNodeIntroduceNew(list.reverse(), '', '', null);
    });

    EventBus.$on('startIntrouduce_all', function (data) {
      // _this.introduceStatus = true;
      _this.startIntrouduce_all();
    });

    EventBus.$on('start', function (data) {
      _this.start();
    });

    EventBus.$on('closeNpl', function (fszl, data) {
      _this.introduceAll = true;
      EventBus.$emit('hzInfo', null); // 关闭火警界面
      _this.setCloseNpl(true);
      _this.fszl('', 'closeNpl');
    });

    EventBus.$on('openNpl', function (fszl, data) {
      _this.introduceAll = false;
      _this.setCloseNpl(false);
      _this.fszl('', 'openNpl');
    });

    EventBus.$on('introduce_openYysb', function (fszl, data) {
      _this.openYysb();
    });

    EventBus.$on('introduce_stopPlay', function (fszl, data) {
      _this.stopPlay();
    });

    EventBus.$on('reviceIntroduce', function (data) {
      if (_this.introduceStatus && data.fszl !== 'introduce_stopPlay') {
        return;
      }
      if (_this.zl === data.fszl) {
        return;
      }
      _this.zl = data.fszl;
      if (data.fszl.indexOf('commonIntrouduce') > -1) {
        EventBus.$emit('commonIntrouduce', data);
      } else {
        EventBus.$emit(_this.zl, data);
      }
    });

    EventBus.$on('dpZlstart', function (data) {
      _this.start();
    });
  },
  created () {
    _this = this;
  },
  destroyed () {
    this.websocket.close();
  }
};
</script>

<style scoped lang="scss">
.introduce_msg{
  z-index: 999;
  font-size: 30px;
  color: white;
  text-align: center;
}

.npl-zb-model{
  position: relative;
  z-index: 999;
  height: 100%;
  pointer-events: none;

}

.dev-bg{
  position: fixed;
  bottom: 0;
  opacity: 0.9;
  z-index: 999;
  width: 100%;
  height: 100px;
  line-height: 100px;
  color: #fff;
  // bottom: 0px;
  // bottom: 10px;
  background: #050505;
}
// .introduce_msg {
//   // position: absolute;
//   // margin-top: 45%;
//   left:170px;
//   height: 50px;
//   width: 100%;
//   background:#fff -webkit-linear-gradient(left, #91b9f7, #91b9f7) no-repeat 0 0;
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   font-size: 30px;
//   // text-align: center;
//   background-size:0 100%;
//   padding: 19px;
//   z-index: 999;
//   opacity:100%
// }
.move {
  animation: hue 19s linear;
}
@keyframes hue {
  0% {
    background-size: 0 100%;
  }

  100% {
    background-size: 100% 100%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: black;
  transition: opacity 5s ease;
  pointer-events: none;
}

.overlay.is-visible {
  opacity: 0;
}

</style>
