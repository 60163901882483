<template>
  <div class="c-sinfo">
    <div class="c-sinfo__header">
      <span class="c-sinfo__header_title">  建筑物详情</span>
    </div>
    <div class="c-sinfo__body">
      <div
        class="c-sinfo__detail"
        :style="{ width: LWDW_VIDEO === 'TRUE' ? '63%' : '98%' }"
      >
        <ul class="c-sdt">
          <li class="c-sdt__item">
            <span class="c-sdt__label">建筑物名称：</span>
            <span class="c-sdt__content">{{ jzwInfo.jzwmc }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">耐火等级：</span>
            <span class="c-sdt__content">{{ jzwInfo.nhdjMc }}</span>
          </li>
          <!-- <li class="c-sdt__item">
            <span class="c-sdt__label">火灾危险性：</span>
            <span class="c-sdt__content">一级耐火</span>
          </li> -->
          <li class="c-sdt__item">
            <span class="c-sdt__label">建造时期：</span>
            <span class="c-sdt__content">{{ jzwInfo.jzrq }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">建筑面积：</span>
            <span class="c-sdt__content">{{ jzwInfo.jzmj }}㎡</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">建造高度：</span>
            <span class="c-sdt__content">{{ jzwInfo.jzgd }}米</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">建筑层数：</span>
            <span class="c-sdt__content">{{ jzwInfo.jzcs }}</span>
          </li>
        </ul>
        <ul class="c-sdt">
          <li class="c-sdt__item">
            <span class="c-sdt__label">消防安全管理员：</span>
            <span class="c-sdt__content">{{ jzwInfo.xfaqglrmc }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">安全管理员电话：</span>
            <span class="c-sdt__content">{{ jzwInfo.xfaqglrdh }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">建（构）筑物类别：</span>
            <span class="c-sdt__content">{{ jzwInfo.jzlbMc }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">避难层数量：</span>
            <span class="c-sdt__content">{{ jzwInfo.bncs }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">安全出口数量：</span>
            <span class="c-sdt__content">{{ jzwInfo.aqcks }}</span>
          </li>
          <li class="c-sdt__item">
            <span class="c-sdt__label">消防电梯数量：</span>
            <span class="c-sdt__content">{{ jzwInfo.xfdts }}</span>
          </li>
        </ul>
      </div>
      <div
        v-if="LWDW_VIDEO=='TRUE'"
        class="c-sb-model"
      >
        <div class="dev-bottom-sp-bg">
          <span class="dev-bottom-sp-bg_title">实时视频</span>
        </div>

        <div class="c-sb-model__inner">
          <video-hls
            :video-url="videoUrl"
            :vimage="vimage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { monitorService } from '../monitor.service';
// import videoView from '../../video/video-view2.vue';
import videoHls from '../../video/video-hls.vue';
import config from '@/config.js';
import { getEnvInfo } from '@/features/feature.common.service';

export default {
  name: 'BottomBox',
  components: {
    // videoView
    videoHls
  },
  props: {
    activeMonitoringRoom: {
      type: String,
      default: null
    },
    activeJzw: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      // 建筑物概述信息
      jzwInfo: {},
      videoUrl: '', // 视频播放地址
      vimage: '/assets/video.png', // 视频封面地址
      LWDW_VIDEO: 'TRUE'
    };
  },
  computed: {
    currentUser () {
      return this.$HMStoreService.getUserinfo();
    }
  },
  methods: {
    queryJzw () {
      monitorService.queryJzw(this.activeMonitoringRoom, this.activeJzw)
        .then(rsp => {
          this.jzwInfo = rsp.data;
        })
        .catch(err => {
          console.error('获取建筑物信息错误！', err);
        });
    },
    initVideoUrl () {
      if (!this.LWDW_VIDEO) return;
      const _this = this;
      // 组装url地址
      const hasToken = this.$HMCookies.get(config.localTokenName) || false;
      // 获取播放地址，首先获取播放IP
      monitorService.getVideoHost()
        .then(rsp => {
          // 组装地址
          if (rsp.data) {
            _this.videoUrl = rsp.data + '/' + _this.activeMonitoringRoom + '/001/hls.m3u8?time=' + new Date().getTime() + '&token=' + hasToken;
          }
        });
    },
    getEnvInfo () {
      const ssdw = this.currentUser.capacity.deptCode;
      getEnvInfo(ssdw, 'LWDW_VIDEO')
        .then(resp => {
          console.log(resp.data.envValue);
          this.LWDW_VIDEO = resp.data.envValue;
          console.log('获取联网单位LWDW_VIDEO', this.LWDW_VIDEO);
        });
    }
  },
  watch: {
    activeMonitoringRoom (newVal, oldVal) {
      if (this.activeMonitoringRoom) {
        this.queryJzw();
        this.initVideoUrl();
      }
    },
    activeJzw () {
      this.queryJzw();
    }
  },
  created () {
    this.queryJzw();
    // const videoProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    // this.videoUrl = `${rtspApi || window.location.host}/test/live`;
    this.getEnvInfo();
    this.initVideoUrl();
  }
};
</script>

<style scoped lang="scss">
@import '../../virtual/chart/chart.scss';

.c-sinfo{
  box-shadow: inset 0 0 18px 2px #0093ff66;
  // background: url('/images/screen/bottom-box-bg.svg') no-repeat center top;
  // background-size: cover;
  background-image: linear-gradient(180deg, #001235f0 0%, #011733f0 100%);

  &__header{
    height: 30px;
    background: url('/monitor/images/tj/bottom.png');

    &_title{
      margin-left: 25px;
      font-family: HYYaKuHei-85J;
      font-size: 16px;
      font-weight: 85J;
      line-height: 24px;
      color: #FFF;

    }
  }

  &__body{
    display: flex;
    width: 100%;
    padding: 20px 24px 0 20px;
  }
}

.u-sub_title{
  display: block;
  width: 182px;
  height: 20px;
  padding-left: 4px;
  margin-bottom: 12px;
  border-left: 4px solid #10B6FF;
  font-size: 14px;
  line-height: 20px;
  background-image: linear-gradient(270deg, rgba(0,0,0,0.00) 0%, rgba(0,152,255,0.45) 100%);
}

.c-sb-model{
  width: 30%;
}

.c-sb-model__inner{
  width: 358.39px;
  height: 186px;
  margin-top: 9px;
  border: 1px solid rgba(16,182,255,1);
  border-radius: 8px;

  img{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
}

.c-sinfo__detail{
  display: flex;
  width: 63%;

  .c-sdt{
    width: 49%;

    &__item {
      align-items: center;
      justify-content: flex-start;

      &:nth-child(2n-1){
        height: 36px;
        background: #044c8a4d;
      }
    }

    &__label{
      flex-shrink: 0;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: 400;
      color: #E2F3FF;

      &::before{
        content: '';
        display: inline-block;
        position: relative;
        top: 3px;
        width: 21px;
        height: 15px;
        margin-right: 8px;
        background: url('/monitor/images/tj/bottom-jz.png');
      }
    }

    &:last-child .c-sdt__label{
      flex-shrink: 0;

    }

    &__content{
      padding-left: 16px;
      font-family: AlibabaPuHuiTi_2_55_Regular;
      font-size: 14px;
      font-weight: 400;
      color: #00CEFF;
    }
  }
}

.dev-bottom-sp-bg{
  width: 358px;
  height: 24px;
  text-align: center;
  background: url('/monitor/images/tj/bottom-sxt.png');

  &_title{
    font-family: AlibabaPuHuiTi_2_85_Bold;
    font-size: 14px;
    font-weight: 700;
    line-height: 1px;
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 4px #0000004d;
  }
}

</style>
