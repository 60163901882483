<template>
  <!-- 多火警列表 消防设施 -->
  <div class="hjlb-container">
    <div
      v-for="(item, index) in hjxxList"
      :key="item.id"
      class="hjlb-container-item"
      :style="{'background': item.id === slxxId ?
        `url('/monitor/images/hj/火警列表_矩形选中.svg') no-repeat center center` :
        `url('/monitor/images/hj/火警列表_矩形.svg') no-repeat center center`}"
      @click="handlechangeHzSeleteId(item)"
    >
      <div class="hjlb-container-item-text">
        <div v-show="item.id ===slxxId">
          <img
            src="/monitor/images/hj/选择框_选中.svg"
            class="hjlb-container-item-img"
          >
        </div>
        <div v-show="item.id !==slxxId">
          <img
            src="/monitor/images/hj/选择框_未选中.svg"
            class="hjlb-container-item-img"
          >
        </div>
        {{ item.hzdd }}
        <div>
          <img
            v-show="index ===0"
            src="/monitor/images/hj/首警.svg"
            class="hjlb-container-item-shoujing"
          >
        </div>
      </div>
      <!-- <div class="hjlb-container-item-sj" /> -->
      <div
        v-if="showHjVideo"
        class="hjlb-container-item-sj"
      >
        {{ item.bjmc.substring(0,item.bjmc.lastIndexOf("-")) }} {{ item.scbjsj | date('yyyy-MM-dd HH:mm:ss') }}
      </div>
      <div
        v-if="!showHjVideo"
        class="hjlb-container-item-sj"
      >
        {{ item.azwz }} {{ item.bjmc.substring(0,item.bjmc.lastIndexOf("-")) }} <br>
        {{ item.scbjsj | date('yyyy-MM-dd HH:mm:ss') }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HjlbTJ',
  components: {

  },
  props: {
    hjxxList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    bjztPageList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    slxxId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    };
  },
  computed: {
    showHjVideo () {
      // 控制是否展示火警视频
      // 如果当前用户单位是清远康大、颐和等，则增加展示部件的安装位置
      const lwdw = this.$HMStoreService.getUserinfo().dept.deptCode;
      if (lwdw === '2c9d80938a6e461c018ab24f59316041' || lwdw === '2c9d80938a6e461c018ab24e007b6040' || lwdw === '2c9d80938a6e461c018ab0c975a65fa0') {
        return false;
      }
      return true;
    }
  },
  methods: {
    handlechangeHzSeleteId (item) {
      if (this.slxxId === item.id) {
        return;
      }
      this.$emit('handlechangeHzSeleteId', item.id);
    }
  },
  watch: {
    hjxxList (val) {
      console.log('hjxxList新的值', val);
    }
  },
  mounted () {
  },
  beforeDestroy () {
  }
};
</script>

<style scoped lang="scss">
.hjlb-container{
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

.hjlb-container::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.hjlb-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.hjlb-container:hover::-webkit-scrollbar-thumb {
  height: 20px;
  background: #09369A;
}

.hjlb-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.hjlb-container-item{
  height: 63px;;
  // background: url('/monitor/images/hj/火警列表_矩形选中.svg') no-repeat center center;
  background-size: contain; // 或者选择其他的适应方式，如cover

  display: flex;
  flex-direction: column; // 让子元素垂直排列
  justify-content: center; // 垂直居中
  align-items: flex-start; //

  margin-top: 8px;
  position: relative; // 添加相对定位
}

.hjlb-container-item-text{
  font-family: AlibabaPuHuiTi_2_55_Regular;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;

  display: flex;
  align-items: center;

}
.hjlb-container-item-sj{
  font-family: AlibabaPuHuiTi_2_55_Regular;
  font-weight: 400;
  font-size: 14px;
  color: #00CDFF;
  letter-spacing: 0;
  margin-left: 42px;
  margin-top: 8px;
}
.hjlb-container-item-img{
  width: 13px;
  height: 13px;
  margin-left: 20px;
  margin-right: 10px;
}
.hjlb-container-item-shoujing{
  width: 64px;
  height: 26px;

  position: absolute;
  right: 20px;
  top: 50%; // 可以调整这个值，使其垂直居中或根据需要放置
  transform: translateY(-50%); // 如果需要垂直居中
}

</style>
