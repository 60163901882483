<template>
  <div class="video-box">
    <video
      ref="videoPlayer"
      :poster="vimage"
    />
  </div>
</template>

<script>
import Hls from 'hls.js';

export default {
  name: 'Home',
  components: {},
  props: {
    videoUrl: {
      type: String,
      default: null,
      required: true
    },
    vimage: {
      type: String,
      default: '/assets/video.png',
      required: true
    }
  },
  data () {
    return {
      hls: null
    };
  },
  methods: {
    play () {
      if (Hls.isSupported()) {
        console.log('videoUrl', this.videoUrl);
        const video = this.$refs.videoPlayer;
        const hls = new Hls();
        hls.loadSource(this.videoUrl);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
        });
        this.hls = hls;
      }
    }
  },
  watch: {
    videoUrl () {
      // 如果this.hls正在播放，则断开
      if (this.hls) {
        this.hls.destroy();
      }
      this.play();
    }
  },
  mounted () {
    this.play();
  },
  beforeDestroy () {
    if (this.hls) {
      this.hls.destroy();
    }
  },
  activated () {
  },
  deactivated () {

  }
};
</script>
<style>
.video-box {
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

video::-webkit-media-controls-play-button {
  display: none;
}

video::-webkit-media-controls-current-time-display {
  display: none;
}

video::-webkit-media-controls-timeline {
  display: none;
}
</style>
