<template>
  <div id="BMap" />
</template>

<script>
import axios from 'axios';

export default {
  name: 'BMapVue',
  props: {
    dwList: {
      type: Array,
      default: () => {
        return [
          // {
          //   code: 1,
          //   name: '鸿蒙智慧消防实验室',
          //   dz: '广州市海珠区新港东路1066号一层A7、A8商铺自编1-E01',
          //   lwzt: '0',
          //   zbry: '刘波',
          //   xfaqzrrmc: '江小白',
          //   lng: 113.375879, // 地理经度
          //   lat: 23.103363// 地理纬度
          // }
        ];
      }
    }
  },
  data () {
    return {
      map: null,
      titleMarker: [],
      currentDw: null,
      currentJzw: null,
      currentPosition: null
    };
  },
  methods: {
    changeDw (dw) {
      this.currentDw = dw.code;
      if (!dw || !dw.lng || !dw.lat) return;
      const position = this.getDwPosition(dw);
      if (this.currentPosition !== position) {
        this.flyTo(position);
      }
      // 重置标注
      this.addMapMarkers(this.dwList, dw);
    },
    flyTo (position) {
      const map = this.map;
      map.setCenter(new BMapGL.Point(position.lng, position.lat), {
        noAnimation: false,
        callback: () => {
          map.setZoom(position.zoom ? position.zoom : 19, {
            noAnimation: false,
            callback: () => {
              map.setTilt(50);// 设置倾斜角度
            }
          });
        }
      });
      this.currentPosition = position;
    },
    getDwPosition (dw) {
      let position = { lng: dw.lng, lat: dw.lat };
      if (dw.jzqbz === 'Y') {
        // 计算建筑群的中心点
        const pointList = [];
        dw.jzwList.forEach(jzw => {
          pointList.push({ lat: jzw.dzLat, lng: jzw.dzLng });
        });
        position = this.getCenterPoint(pointList);
      }
      return position;
    },
    resetMap () {
      const centerPoint = this.getCenterPoint(this.dwList);
      // const centerPoint = { lng: 113.310245, lat: 23.090151, zoom: 13.5 };
      this.flyTo(centerPoint);
      this.currentPosition = null;
    },
    // 给所有标注添加点击事件
    addMapMarkerEvent () {
      var _this = this;
      this.map.getOverlays().forEach(marker => {
        marker.addEventListener('click', function () {
          const dw = marker.hm_data;
          const isBack = _this.currentDw === dw.code;
          if (dw.markerType === 'jzw' && dw.jzwId !== _this.currentJzw) {
            console.log('click jzw');
            // 建筑群标注
            _this.currentJzw = dw.jzwId;
            _this.$parent.changeDetail(true, dw.code, dw.jzwId);
            return;
          }
          if (isBack) {
            console.log('click back');
            _this.resetMap();
            _this.$parent.changeDetail(false);
            _this.currentDw = null;
          } else {
            console.log('click dw', dw);
            _this.flyTo(this.this.getDwPosition(dw));
            _this.$parent.changeDetail(true, dw.code);
            _this.currentDw = dw.code;
          }
          // 重置标注
          _this.addMapMarkers(_this.dwList, isBack ? null : dw);
        });
        marker.hm_mouseover !== 'none' &&
        marker.addEventListener('mouseover', function (e) {
          const target = e.target;
          console.log('mouseover', e, target.hm_data, target instanceof BMapGL.Marker);
          _this.cleanTitleMarker();
          _this.addTitleMarker(target.hm_data);
        });
      });
    },
    addTitleMarker (dw) {
      var _this = this;
      const icon = {
        width: 134,
        height: 59,
        url: '/monitor/images/bdmap/marker_title.png'
      };
      const content = dw.name.substr(0, 6);
      const sign = dw.name.length > 6 ? '..' : '';
      const fontSize = 16;
      const label = {
        content: content + sign,
        fontSize: fontSize,
        color: '#FFF',
        offsetWidth: -(fontSize * content.length / 2),
        offsetHeight: -icon.height + fontSize + 21
      };
      const titleMarker = _this.addMapMarker(dw, icon, label);
      _this.titleMarker.push(titleMarker);
      const position = _this.getDwPosition(dw);
      titleMarker.addEventListener('click', function (e) {
        _this.flyTo(position);
        _this.$parent.changeDetail(true, dw.code);
        _this.currentDw = dw.code;
        // 重置标注
        _this.addMapMarkers(_this.dwList, dw);
      });
      titleMarker.addEventListener('mouseout', function (e) {
        _this.cleanTitleMarker();
      });
    },
    cleanTitleMarker () {
      this.titleMarker.forEach(marker => {
        this.map.removeOverlay(marker);
      });
    },
    addMapMarkers (dwList, selectDw) {
      this.cleanAllMarker();
      if (selectDw) {
        dwList = dwList.filter(el => el.code !== selectDw.code);
        if (selectDw.jzqbz === 'Y') {
          // 给每个建筑物标注
          selectDw.jzwList.forEach((jzw, index) => {
            if (jzw.dzLng && jzw.dzLat) {
              const icon = {
                width: 91,
                height: 115,
                // url: selectDw.lwzt === '1' ? '/monitor/images/bdmap/marker_online_selected.png' : '/monitor/images/bdmap/marker_unline_selected.png'
                url: '/monitor/images/bdmap/marker_online_selected.png'
              };
              const content = jzw.jzwmc.substr(0, 7);
              const sign = jzw.jzwmc.length > 7 ? '..' : '';
              const fontSize = 16;
              const label = {
                content: content + sign,
                fontSize: fontSize,
                color: '#CFE6FF',
                offsetWidth: -(fontSize * content.length / 2) + 5,
                offsetHeight: -(icon.height / 2) + 0
              };
              const dw = {
                lat: jzw.dzLat,
                lng: jzw.dzLng,
                code: selectDw.code,
                jzwId: jzw.jzwId,
                markerType: 'jzw'
              };
              if (index === 0) {
                this.currentJzw = jzw.jzwId;
              }
              const marker = this.addMapMarker(dw, icon, label);
              marker.hm_mouseover = 'none';
            }
          });
        } else {
          // 给单位标注
          const icon = {
            width: 91,
            height: 115,
            // url: selectDw.lwzt === '1' ? '/monitor/images/bdmap/marker_online_selected.png' : '/monitor/images/bdmap/marker_unline_selected.png'
            url: '/monitor/images/bdmap/marker_online_selected.png'
          };
          const content = selectDw.name.substr(0, 6);
          const sign = selectDw.name.length > 6 ? '..' : '';
          const fontSize = 16;
          const label = {
            content: content + sign,
            fontSize: fontSize,
            color: '#CFE6FF',
            offsetWidth: -(fontSize * content.length / 2) + 5,
            offsetHeight: -(icon.height / 2) + 0
          };
          const marker = this.addMapMarker(selectDw, icon, label);
          marker.hm_mouseover = 'none';
        }
      }
      dwList.forEach(el => {
        const icon = {
          width: el.lwzt === '1' ? 25.6 : 19.2,
          height: el.lwzt === '1' ? 32 : 24,
          url: el.lwzt === '1' ? '/monitor/images/bdmap/marker_online.png' : '/monitor/images/bdmap/marker_unline.png'
        };
        this.addMapMarker(el, icon);
      });
      this.addMapMarkerEvent();
    },
    addMapMarker (dw, icon, addLabel = false) {
      if (!dw || !dw.lng || !dw.lat) return;
      const myIcon = new BMapGL.Icon(icon.url, new BMapGL.Size(icon.width, icon.height));
      const point = new BMapGL.Point(dw.lng, dw.lat);
      const marker = new BMapGL.Marker(point, { icon: myIcon });
      if (addLabel) {
        const fontSize = addLabel.fontSize;
        const label = new BMapGL.Label(addLabel.content, { position: point, offset: new BMapGL.Size(addLabel.offsetWidth, addLabel.offsetHeight) });
        label.setStyle({ color: addLabel.color, fontSize: fontSize + 'px', 'line-height': fontSize + 'px', border: 'none', background: 'none' });
        marker.setLabel(label);
      }
      // marker.setTitle(dw.name);
      marker.hm_data = dw;
      this.map.addOverlay(marker);// 创建标注
      return marker;

      // 参考http://api.map.baidu.com/library/RichMarker/1.2/docs/symbols/BMapLib.RichMarker.html
      // 参考https://github.com/huiyan-fe/BMapGLLib
    //   const htm = `<div class="richWrapper">
    //   <div class="richTitle">${dw.name}</div>
    //   <div class="richContent">
    //     <ul>
    //       <li><div class="richContent-left">状态：</div><div class="richContent-right">${dw.lwzt === '1' ? '在线' : '离线'}</div></li>
    //       <li><div class="richContent-left">值班人员：</div><div class="richContent-right">${dw.zbry ? dw.zbry : ''}</div></li>
    //       <li><div class="richContent-left">消防负责人：</div><div class="richContent-right">${dw.xfaqzrrmc ? dw.xfaqzrrmc : ''}</div></li>
    //       <li><div class="richContent-left">地址：</div><div class="richContent-right">${dw.dz ? dw.dz : ''}</div></li>
    //     </ul>
    //   </div>
    // </div>`;
    //   const marker = new BMapGLLib.RichMarker(htm, point, { anchor: new BMapGL.Size(0, -200), enableDragging: false });
    //   marker.hm_dwid = dw.code;
    //   marker.hm_name = dw.name;
    //   this.map.addOverlay(marker);// 创建富文本标注
    },
    cleanAllMarker () {
      this.map.getOverlays().forEach(marker => {
        this.map.removeOverlay(marker);
      });
    },
    getCenterPoint (pointList) {
      if (pointList.length <= 0) return { lng: 113.277923, lat: 23.139206, zoom: 13.5 };
      const points = [];
      pointList.forEach(dw => {
        if (dw.lng && dw.lat) {
          points.push(new BMapGL.Point(dw.lng, dw.lat));
        }
      });
      const viewPort = this.map.getViewport(points);
      return { lng: viewPort.center.lng, lat: viewPort.center.lat, zoom: viewPort.zoom - 1 };
    },
    setMapStyle () {
      const _this = this;
      axios.get('/monitor/map_config.json').then(resp => {
        console.log(resp);
        const json = resp.data;
        _this.map.setMapStyleV2({ styleJson: json });
      });
    }
  },
  watch: {
    dwList (newVal, oldVal) {
      var _this = this;
      _this.resetMap();

      if (this.dwList) {
        // 遍历添加标注
        this.addMapMarkers(this.dwList);
      }
    }
  },
  mounted () {
    /* global BMapGL */
    const map = this.map = new BMapGL.Map('BMap');
    map.centerAndZoom(new BMapGL.Point(113.277923, 23.139206), 15); // 初始化地图,设置中心点坐标和地图级别
    window.map = map;
    map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
    this.resetMap();

    // 个性化地图样式
    this.setMapStyle();

    // 添加控件
    // map.addControl(new BMapGL.MapTypeControl());// 默认位于地图右上方，切换地图类型
    // map.addControl(new BMapGL.ScaleControl());//  默认位于地图左下方，显示地图的比例关系
    // map.addControl(new BMapGL.ZoomControl());//  默认位于地图右下方，控制地图级别的缩放
    // map.addControl(new BMapGL.NavigationControl3D());// 默认位于地图右上方，可以对地图进行旋转以及切换地图2D、3D展示效果
    // map.addControl(new BMapGL.LocationControl());//  默认位于地图左下方，用于获取定位
    // map.addControl(new BMapGL.CityListControl());//  默认位于地图左上方，用于进行城市选择定位
    // const copyrightCtrl = new BMapGL.CopyrightControl();
    // copyrightCtrl.addCopyright({ id: 123, content: '鸿蒙' });
    // map.addControl(copyrightCtrl);//  默认位于地图左下方，用于展示版权信息
  }
};
</script>

<style lang="scss">

.richWrapper * {
  padding: 0;
  margin: 0;
}

.richWrapper {
  box-sizing: border-box;
  width: 336px;
  height: 206px;
  padding: 10px 10px;
  background: url('/monitor/images/richMaker_dialog.png') no-repeat center center;
  background-size: cover;
}

.richTitle {
  width: 100%;
  height: 45px;
  padding: 0 0 0 16px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 16px;
  font-weight: 600;
  line-height: 45px;
  color: #fffefe;
}

.richContent li {
  display: flex;
  justify-content: space-between;
  height: 30px;
  list-style: none;
}

.richContent {
  padding: 10px 20px 20px 20px;

  &-left {
    flex-shrink: 0;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    color: #daf0ff;
  }

  &-right {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    color: #5afff7;
  }
}
</style>
